import React from "react"
import useMediaQuery from "@mui/material/useMediaQuery"
import AppManager from "services/app-manager"
import Layout from "components/layout"
import { graphql, useStaticQuery } from "gatsby"
import { Typography } from "components/typography"
import { ListHeaderProvider } from "components/content/list-header"
import { PersonalLoanDisclaimer } from "components/content/content-disclaimer"
import Faq from "components/content/faq"
import { chunkifyArray } from "components/utils"
import { MapToDiv } from "components/content/map-to-div"
import ProductCategoryPageTopSection from "components/content/product-category-page-top-section"
import { SectionTextDivider, SectionTextSubdivider } from "components/content/product-category-page-section-header"

const CarLoansView = () => {
  const dateUpdated = AppManager.getInstance().getProductCategoryDateUpdated("cl")
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
  const dateString = dateUpdated ? dateUpdated.substring(8, 10) + " " + months[parseInt(dateUpdated.substring(5, 7)) - 1] + " " + dateUpdated.substring(0, 4) : ""

  const isWide = useMediaQuery("(min-width:768px)")
  const chunkSize = isWide ? 4 : 2
  const features = [
    { name: "Fixed Rate", urlSlug: "fixed-rate-car-loans", img: "/images/icon_fixed_rate.png" },
    { name: "Variable Rate", urlSlug: "variable-rate-car-loans", img: "/images/icon_variable_rate.png" },
    { name: "Unsecured", urlSlug: "unsecured-car-loans", img: "/images/cash_back.png" },
    { name: "Secured", urlSlug: "secured-car-loans", img: "/images/icon_car_loan.png" },
  ]
  const featureLines = chunkifyArray(features, chunkSize)

  const plProviders: object = AppManager.getInstance().getReducedCarLoanProviders()
  // const mainItems = Object.values(plProviders).filter((x: any) => x.big4 && x.popular && x.hasProduct).sort((a: any, b: any) => (a.id > b.id ? 1 : -1))
  // const otherItems = Object.values(plProviders).filter((x: any) => !x.big4 && x.popular && x.hasProduct).sort((a: any, b: any) => (a.id > b.id ? 1 : -1))
  const mainItems = Object.values(plProviders)
    .filter((x: any) => x.big4 && x.hasProduct)
    .sort((a: any, b: any) => (a.id > b.id ? 1 : -1))
  const otherItems = Object.values(plProviders)
    .filter((x: any) => !x.big4 && x.hasProduct)
    .sort((a: any, b: any) => (a.id > b.id ? 1 : -1))
  const majors = chunkifyArray(mainItems, chunkSize)
  const popular = chunkifyArray(otherItems, chunkSize)

  const queryResult = useStaticQuery(graphql`
    {
      allContentfulContentQa {
        nodes {
          slug
          qa {
            qa
          }
        }
      }
    }
  `)
  const qa = queryResult.allContentfulContentQa.nodes.find((x: any) => x.slug === "/car-loans")

  let minRate = Number.MAX_VALUE
  let minRateComparisonRate = Number.MAX_VALUE
  let minLoanAmount = Number.MAX_VALUE
  let maxLoanAmount = Number.MIN_VALUE

  Object.values(plProviders).forEach(provider => {
    if (provider.minRate < minRate) {
      minRate = provider.minRate
      minRateComparisonRate = provider.minRateComparisonRate
    }
    if (provider.minLoanAmount < minLoanAmount) {
      minLoanAmount = provider.minLoanAmount
    }
    if (provider.maxLoanAmount > maxLoanAmount) {
      maxLoanAmount = provider.maxLoanAmount
    }
  })

  let description = "Get a better car loan deal from your bank and across the market, including all-purpose Personal Loans. Rates from "
  description += (minRate * 100).toFixed(2) + "% p.a. (" + (minRateComparisonRate * 100).toFixed(2) + "% p.a. comparison rate*). Loans from $" + minLoanAmount.toLocaleString() + " up to $" + maxLoanAmount.toLocaleString() + "."

  return (
    <div>
      <ProductCategoryPageTopSection productCategory="cl" dateString={dateString} pageHeading={`Compare car loans`} categoryDescription={description} />

      <div>
        <div style={{ marginTop: "60px", marginBottom: "130px" }}>
          <SectionTextDivider heading="Compare car loans by feature" text="Compare car loans with variable or fixed rates and secured or unsecured loans." />
          <MapToDiv.Features features={featureLines} />
        </div>
        {/* <TopPicks /> */}
      </div>

      <div style={{ marginTop: "110px" }} id="providers">
        <SectionTextDivider heading="Compare leading car loan brands" text="Compare car loan offers from the major banks and other popular brands." />
        <SectionTextSubdivider subheading="Majors" text="Big banks with extensive nationwide networks that provide a comprehensive range of services" />
        <MapToDiv.Banks banks={majors} productCategory="car-loans" />
        <SectionTextSubdivider subheading="Challengers" text="Regional and international banking institutions that offer financial services" />
        <MapToDiv.Banks banks={popular} productCategory="car-loans" />
      </div>

      <div>
        {qa && (
          <div style={{ marginTop: "100px" }}>
            <ListHeaderProvider>{"FAQs"}</ListHeaderProvider>
            {/* {<Markdown md={qa.qa.qa} isArticle={true} />} */}
            <Faq faqContent={qa.qa.qa} />
          </div>
        )}
        <PersonalLoanDisclaimer isDisclaimerOpen isProvider />
      </div>
    </div>
  )
}

const CarLoans = () => {
  return (
    <Layout title="Car Loan Comparison | Stay or Go" description="Compare car loan offers from all the major banks and popular brands." image="/video/StayOrGo_Animation_01.jpf" urlSlug="car-loans">
      <div className="container-center">
        <div className="home-container">
          <CarLoansView />
        </div>
      </div>
    </Layout>
  )
}

export default CarLoans
